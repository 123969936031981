import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';

export default class BooleanCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'boolean';

  public getDisplayValue(data: unknown): string|null {
    const { value } = new DataExtractor(data);
    if (!this.columnMetadata) {
      return value as string;
    }

    const {
      true_label: trueLabel,
      false_label: falseLabel,
      null_label: nullLabel,
      empty_value: emptyValue,
    } = this.columnMetadata;

    switch (value) {
      case 1:
      case '1':
        return trueLabel ?? value.toString();
      case 0:
      case '0':
        return falseLabel ?? value.toString();
      default:
        return nullLabel ?? (value ? null : emptyValue) as string;
    }
  }
}
