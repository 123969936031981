import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import CellValueStrategy from '@/helpers/mappers/DataTable/cellValueStrategies/CellValueStrategy';
import { Value } from '@/types/DataTableTypes';

export default class Cell {
  protected readonly data: unknown;

  private cellValueStrategy: CellValueStrategy;

  private cellDisplayStrategy: CellDisplayStrategy;

  constructor(
    data: unknown,
    cellValueStrategy: CellValueStrategy,
    cellDisplayStrategy: CellDisplayStrategy,
  ) {
    this.data = data;
    this.cellValueStrategy = cellValueStrategy;
    this.cellDisplayStrategy = cellDisplayStrategy;
  }

  public getValue(): Value {
    return this.cellValueStrategy.getValue(this.data);
  }

  public getDisplayValue(): string|null {
    return this.cellDisplayStrategy.getDisplayValue(this.data);
  }
}
