function getSeparator(type: 'decimal'|'group', serverLocale = 'nl-NL'): string {
  const separator = Intl.NumberFormat(serverLocale)
    .formatToParts(1000.1)
    .find((part) => part.type === type);

  if (typeof separator !== 'undefined') {
    return separator.value;
  }

  return type === 'decimal' ? ',' : '.';
}

function symbolStringToSymbol(currencySymbol: string): string {
  return (0).toLocaleString(
    'nl-NL',
    {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  ).replace(/\d/g, '').trim();
}

function numberToCurrencyFormat(input: number|null|string, decimals: number, serverLocale = 'nl-NL'): string {
  let value = input;
  const groupSeparator = getSeparator('group', serverLocale);
  const decimalSeparator = getSeparator('decimal', serverLocale);

  if (value === null) {
    return '';
  }

  value = ((typeof value === 'string' ? parseFloat(value) : value).toFixed(decimals)).toString();

  const [integer, decimal] = value.split('.');
  const integerWithThousandSeparator = integer.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${groupSeparator}`);

  return `${integerWithThousandSeparator}${decimalSeparator}${decimal}`;
}

function currencyFormatToNumberFormat(input: number|string, serverLocale = 'nl-NL'): string {
  const groupSeparator = getSeparator('group', serverLocale);
  const decimalSeparator = getSeparator('decimal', serverLocale);

  if (typeof input === 'number') {
    return input.toString().replace(/\./g, decimalSeparator);
  }

  return input.replaceAll(new RegExp(`[${groupSeparator}]`, 'g'), '');
}

export {
  symbolStringToSymbol, numberToCurrencyFormat, currencyFormatToNumberFormat, getSeparator,
};
