import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';

export default class BackgroundColorDecorator extends Decorator {
  public getElement(): HTMLElement {
    const element = super.getElement();

    if (typeof this.component.properties.backgroundColor === 'string') {
      element.style.backgroundColor = this.component.properties.backgroundColor;
    }

    return element;
  }
}
