import CellValueStrategy from '@/helpers/mappers/DataTable/cellValueStrategies/CellValueStrategy';
import DefaultCellValueStrategy
  from '@/helpers/mappers/DataTable/cellValueStrategies/DefaultCellValueStrategy';

export default class CellValueStrategyFactory {
  // eslint-disable-next-line class-methods-use-this
  public createCellValueStrategy(_: string): CellValueStrategy {
    return new DefaultCellValueStrategy();
  }
}
