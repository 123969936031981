import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';
import { numberToCurrencyFormat, symbolStringToSymbol } from '@/helpers/converters/currency';
import createElement from '@/helpers/mappers/DataTable/cellDisplayDecorator/DecoratorServices';

export default class CurrencyCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'currency';

  public getDisplayValue(data: unknown): string | null {
    let { value, properties } = new DataExtractor(data);
    properties = {
      ...properties,
      whiteSpace: 'nowrap',
      classList: ['float-end'].concat((properties.class ?? []) as Array<string>),
    };

    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (typeof value === 'number') {
      const decimals = this.columnMetadata?.decimals !== undefined ? this.columnMetadata.decimals as number : 2;
      const currencySymbol = this.columnMetadata?.currency !== undefined ? this.columnMetadata.currency as string : 'EUR';
      const stringValue = numberToCurrencyFormat(value, decimals);

      value = `${symbolStringToSymbol(currencySymbol)}&nbsp;${stringValue}`;

      return createElement(value, properties).outerHTML;
    }

    return createElement('', properties).outerHTML;
  }
}
