import { Value } from '@/types/DataTableTypes';

interface Properties {
  [propertyName: string]: unknown
}

export default class DataExtractor {
  private readonly data: unknown;

  constructor(data: unknown) {
    this.data = data;
  }

  get properties(): Properties {
    return (this.data as { properties: Properties })?.properties ?? {};
  }

  get value(): Value {
    if (this.data !== undefined
      && this.data !== null
      && typeof this.data === 'object'
      && !Array.isArray(this.data)
    ) {
      // Return data from value property
      if ('value' in (this.data as never)) {
        return (this.data as { value: Value })?.value;
      }

      if (!('properties' in (this.data as never))) {
        return this.data as Value;
      }

      // Return all the data except the properties
      if (Object.values(this.data as never).length > 1) {
        const {
          properties: _,
          ...data
        } = (this.data as any);
        return data;
      }

      // No data found, return undefined
      return undefined;
    }
    return this.data as Value;
  }
}
