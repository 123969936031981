import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';
import { isArray } from 'lodash';

export default class ClassDecorator extends Decorator {
  public getElement(): HTMLElement {
    const element = super.getElement();
    let { classList } = this.component.properties;

    if (typeof classList === 'string') {
      classList = classList.split(' ');
    }

    if (isArray(classList)) {
      classList.forEach((className: string) => {
        element.classList.add(className);
      });
    }

    return element;
  }
}
