import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';

export default class TextCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'text';

  // eslint-disable-next-line class-methods-use-this
  public getDisplayValue(data: unknown): string | null {
    const { value } = new DataExtractor(data);

    if (typeof value === 'string' && value.length === 0) {
      if (!this.columnMetadata) {
        return '';
      }

      const {
        empty_value: emptyValue,
      } = this.columnMetadata;

      return (emptyValue ?? '').toString();
    }

    if (typeof value === 'string') {
      return value.replaceAll('\n', '<br>');
    }

    if (!this.columnMetadata) {
      return (value ?? '').toString();
    }

    const {
      empty_value: emptyValue,
    } = this.columnMetadata;

    return (value ?? emptyValue ?? '').toString();
  }
}
