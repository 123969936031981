import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';

export default class TextColorDecorator extends Decorator {
  public getElement(): HTMLElement {
    const element = super.getElement();

    if (typeof this.component.properties.color === 'string') {
      element.style.color = this.component.properties.color;
    }

    return element;
  }
}
