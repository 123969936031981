import { Mapper } from '@/helpers/mappers/mapper';
import Row from '@/helpers/mappers/DataTable/DataTableRow';
import Cell from '@/helpers/mappers/DataTable/DataTableCell';
import CellDisplayStrategyFactory
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategyFactory';
import CellValueStrategyFactory
  from '@/helpers/mappers/DataTable/cellValueStrategies/CellValueStrategyFactory';
import { ColumnPriority, Header as DataTableHeader } from '@/types/DataTableTypes';
import {
  Column,
  RowData,
  DataGridInfo,
  DataObject,
  DataObjects,
  MetadataColumnSettings,
  MetadataColumnsSettings,
} from '@/types/ListTypes';

export default class DataTableMapper implements Mapper {
  inputHeader: Column[] = [];

  inputData: DataObjects = [];

  rows: Array<Row> = [];

  headers: Array<DataTableHeader> = [];

  dataGridInfo: DataGridInfo|undefined;

  private columnMetadata: MetadataColumnsSettings = {};

  constructor(dataGridInfo: DataGridInfo, inputData: DataObjects) {
    this.dataGridInfo = dataGridInfo;
    this.inputHeader = dataGridInfo.columns;
    this.inputData = inputData;
  }

  public setColumnMetadata(columnMetadata: MetadataColumnsSettings): void {
    this.columnMetadata = columnMetadata;
  }

  map(): void {
    this.mapHeader();
    this.mapData();
  }

  private mapHeader() {
    this.headers = [];
    this.inputHeader.forEach((column: Column) => {
      this.pushHeader(column.name, column.label);
    });
  }

  private static getPriority(value: number|null|undefined): ColumnPriority {
    if (typeof value === 'number' && value <= 10) return 'low-priority';
    if (typeof value === 'number' && value <= 20) return 'medium-priority';
    if (typeof value === 'number' && value > 20) return 'high-priority';

    return 'no-priority';
  }

  private pushHeader(value: string, text: string) {
    const columnSettings = (this.dataGridInfo?.metadata?.['column-settings'] as MetadataColumnsSettings|undefined)?.[value];
    const priority = DataTableMapper.getPriority(columnSettings?.priority);
    let filterable = false;
    if (this.dataGridInfo?.filters !== undefined) {
      filterable = (this.dataGridInfo?.filters?.findIndex((filterObject) => filterObject?.name === value) !== -1);
    }

    const header: DataTableHeader = {
      text,
      value,
      filterable,
      class: priority,
      cellClass: priority,
      sortable: (this.dataGridInfo?.metadata?.['column-settings'] as MetadataColumnsSettings)?.[value]?.sortable ?? false,
    };
    this.headers.push(header);
  }

  private mapData() {
    this.rows = [];
    this.inputData.forEach((dataObject: DataObject) => {
      this.pushRow(dataObject.data);
    });
  }

  private pushRow(data: RowData) {
    const row: {[columnValue: string]: Cell} = {};
    const headerValues = this.headers.map(({ value }) => value);
    const dataKeys = Object.keys(data);
    const dataStructure = [...new Set([...headerValues, ...dataKeys])];

    dataStructure.forEach((columnName: string) => {
      const cellValueStrategyFactory = new CellValueStrategyFactory();
      const cellValueStrategy = cellValueStrategyFactory.createCellValueStrategy(
        (this.columnMetadata?.[columnName] as MetadataColumnSettings)?.type,
      );
      const cellDisplayStrategyFactory = new CellDisplayStrategyFactory(
        (this.columnMetadata?.[columnName] as MetadataColumnSettings),
      );
      const cellDisplayStrategy = cellDisplayStrategyFactory.createCellDisplayStrategy(data);
      const value = data[columnName];
      row[columnName] = new Cell(value, cellValueStrategy, cellDisplayStrategy);
    });

    this.rows.push(new Row(row));
  }

  private getModuleType(columnName: string): string|undefined {
    const _ = this.headers;
    return columnName ?? undefined;
  }

  public getHeaders(): Array<DataTableHeader> {
    return this.headers;
  }

  public getRows(): Array<Row> {
    return this.rows;
  }
}
