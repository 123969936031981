function formatTime(time: string): string {
  if (time.length > 0) {
    return new Date(time).toTimeString().substr(0, 8);
  }
  return '';
}

class Time {
  private hours = 0;

  private minutes = 0;

  constructor(hours: number|string = 0, minutes: number|string = 0) {
    return this.setTime(hours, minutes);
  }

  public setTime(hours: number|string, minutes: number|string = 0): Time {
    this.setHours(hours);
    return this.setMinutes(minutes);
  }

  public getMinutes(): number {
    return this.minutes;
  }

  public setMinutes(minutesInput: number|string): Time {
    const minutes: number = Math.round(
      typeof minutesInput === 'string'
        ? parseInt(minutesInput, 10)
        : minutesInput,
    );

    if (minutes >= 60) {
      this.hours += 1;
    }
    this.minutes = minutes % 60;

    return this;
  }

  public setMinutesFromDecimal(minutesInput: number|string): Time {
    const decimalOrInteger = typeof minutesInput === 'string'
      ? parseFloat(minutesInput)
      : minutesInput;
    const float = decimalOrInteger < 1
      ? `${minutesInput}`
      : `0.${minutesInput}`;
    const minutes: number = parseFloat(float) * 100;

    return this.setMinutes((minutes / 100) * 60);
  }

  public getHours(): number {
    return this.hours;
  }

  public setHours(hours: number|string): Time {
    this.hours += typeof hours === 'string'
      ? parseInt(hours, 10)
      : hours;

    return this;
  }

  public getTimeString(): string {
    if (Number.isNaN(this.minutes) || Number.isNaN(this.hours)) {
      return '';
    }
    const minutes = this.minutes < 10
      ? `0${this.minutes}`
      : this.minutes;

    const hours = this.hours < 10
      ? `0${this.hours}`
      : this.hours;

    return `${hours}:${minutes}`;
  }
}

export { formatTime, Time };
