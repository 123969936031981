import Cell from '@/helpers/mappers/DataTable/DataTableCell';
import DefaultCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/DefaultCellDisplayStrategy';
import DefaultCellValueStrategy
  from '@/helpers/mappers/DataTable/cellValueStrategies/DefaultCellValueStrategy';
import { Value } from '@/types/DataTableTypes';

interface Cells {
  [columnName: string]: Cell
}

export default class Row {
  private readonly data: Cells = { };

  constructor(row: Cells) {
    const { data_id: dataId, id, ...data } = row;

    // Part of a temp solution. The backend can give a wrong id back. This part can be deleted
    // once the backend is fixed. This solution solve the inline editable field in dialogs and details
    this.data.data_id = new Cell(
      Row.getDataId(dataId?.getValue() as number|undefined, id?.getValue() as number|undefined),
      new DefaultCellValueStrategy(),
      new DefaultCellDisplayStrategy(),
    );
    this.data.id = new Cell(
      Row.getId(dataId?.getValue() as number|undefined, id?.getValue() as number|undefined),
      new DefaultCellValueStrategy(),
      new DefaultCellDisplayStrategy(),
    );

    this.data = { ...this.data, ...data };
  }

  get id(): Value {
    return this.data.id.getValue();
  }

  public getCell(key: string): Cell {
    return this.data[key] ?? null;
  }

  private static getDataId(dataId: number|undefined, id: number|undefined): number|undefined {
    // Temp solution because in the listBlock data_id is missing and swapped with id
    if (dataId === undefined) {
      return id;
    }
    return dataId;
  }

  public static getId(dataId: number|undefined, id: number|undefined): number|undefined {
    return id;
  }
}
