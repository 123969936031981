import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';

export default class PaddingDecorator extends Decorator {
  public getElement(): HTMLElement {
    const element = super.getElement();

    if (typeof this.component.properties.padding === 'string') {
      element.style.padding = this.component.properties.padding;
    }

    if (typeof this.component.properties.paddingTop === 'string') {
      element.style.paddingTop = this.component.properties.paddingTop;
    }

    if (typeof this.component.properties.paddingRight === 'string') {
      element.style.paddingRight = this.component.properties.paddingRight;
    }

    if (typeof this.component.properties.paddingLeft === 'string') {
      element.style.paddingLeft = this.component.properties.paddingLeft;
    }

    if (typeof this.component.properties.paddingBottom === 'string') {
      element.style.paddingBottom = this.component.properties.paddingBottom;
    }

    return element;
  }
}
