// eslint-disable-next-line import/prefer-default-export
import BackgroundColorDecorator
  from '@/helpers/mappers/DataTable/cellDisplayDecorator/BackgroundColorDecorator';
import TextColorDecorator
  from '@/helpers/mappers/DataTable/cellDisplayDecorator/TextColorDecorator';
import WhiteSpaceDecorator
  from '@/helpers/mappers/DataTable/cellDisplayDecorator/WhitespaceDecorator';
import ConcreteComponent from '@/helpers/mappers/DataTable/cellDisplayDecorator/ConcreteElement';
import PaddingDecorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/PaddingDecorator';
import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';
import ClassDecorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/ClassDecorator';

export default function createElement(value: string|number, properties: Record<string, unknown>): HTMLElement {
  let element: ConcreteComponent|Decorator = new ConcreteComponent(value.toString(), properties);
  const decorators: Array<typeof Decorator> = [
    BackgroundColorDecorator,
    TextColorDecorator,
    WhiteSpaceDecorator,
    PaddingDecorator,
    ClassDecorator,
  ];

  decorators.forEach((DecoratorClass) => {
    element = new DecoratorClass(element);
  });

  return element.getElement();
}
