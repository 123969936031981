import useTenantSettingsStore from '@/stores/tenantSettings';

// Returns a ISO 8601 format. I.E. 2013-07-02T09:00:00-7:00
function convertToISOString(date: Date): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num: number): string => (num < 10 ? '0' : '') + num;

  return `${date.getFullYear()
  }-${pad(date.getMonth() + 1)
  }-${pad(date.getDate())
  }T${pad(date.getHours())
  }:${pad(date.getMinutes())
  }:${pad(date.getSeconds())
  }${dif}${pad(Math.floor(Math.abs(tzo) / 60))
  }:${pad(Math.abs(tzo) % 60)}`;
}

function convertToDateTimeToLocaleString(value: string, locale: string, dateTimeFormatOptions: Intl.DateTimeFormatOptions = { }): string {
  const usingLocale = dateTimeFormatOptions?.month === 'long' ? locale : 'nl';

  return new Date(value).toLocaleString(usingLocale, dateTimeFormatOptions);
}

export { convertToISOString, convertToDateTimeToLocaleString };
