import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';

export default class UrlCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'url';

  public getDisplayValue(data: unknown): string|null {
    const { value } = new DataExtractor(data);

    if (!value
      || typeof value === 'undefined'
      || (typeof value === 'string' && value.length === 0)
    ) {
      if (this.columnMetadata) {
        const {
          empty_value: emptyValue,
        } = this.columnMetadata;

        return emptyValue ?? '';
      }
      return '';
    }

    if (this.columnMetadata
      && this.columnMetadata.display_type === 'value'
      && typeof this.columnMetadata.display_content === 'string'
    ) {
      return this.columnMetadata.display_content;
    }

    if (this.columnMetadata
      && this.columnMetadata.display_type === 'column'
      && this.columnMetadata.display_column
      && typeof this.rowData !== 'undefined'
    ) {
      const displayValue = this.rowData[this.columnMetadata.display_column];
      if (typeof displayValue === 'string') {
        return displayValue;
      }
    }

    return value.toString();
  }
}
