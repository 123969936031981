import { MetadataColumnSettings, RowData } from '@/types/ListTypes';

export default abstract class CellDisplayStrategy {
  protected abstract cellType: string = 'default';

  protected rowData: RowData|undefined;

  protected columnMetadata: MetadataColumnSettings|undefined;

  constructor(columnMetadata?: MetadataColumnSettings, rowData?: RowData) {
    this.columnMetadata = columnMetadata;
    this.rowData = rowData;
  }

  public abstract getDisplayValue(data: unknown): string|null

  public getType(): string {
    return this.cellType;
  }
}
