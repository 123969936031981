import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';
import useTenantSettingsStore from '@/stores/tenantSettings';
import { convertToDateTimeToLocaleString } from '@/helpers/converters/date';
import createElement from '@/helpers/mappers/DataTable/cellDisplayDecorator/DecoratorServices';

export default class DateCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'date';

  private tenantSettings = useTenantSettingsStore();

  public getDisplayValue(data: unknown): string | null {
    const { properties } = new DataExtractor(data);
    let { value } = new DataExtractor(data);

    if (typeof value === 'string') {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: this.columnMetadata?.human_moments ? 'long' : 'numeric',
        day: 'numeric',
      };
      value = convertToDateTimeToLocaleString(value, this.tenantSettings.datetimeLocale, options);
      properties.whiteSpace = 'nowrap';

      return createElement(value, properties).outerHTML;
    }

    return createElement('', properties).outerHTML;
  }
}
