import Decorator from '@/helpers/mappers/DataTable/cellDisplayDecorator/Decorator';

export default class WhiteSpaceDecorator extends Decorator {
  public getElement(): HTMLElement {
    const element = super.getElement();

    if (typeof this.component.properties.whiteSpace === 'string') {
      element.style.whiteSpace = this.component.properties.whiteSpace;
    }

    return element;
  }
}
