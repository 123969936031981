import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';

export default class DefaultCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'default';

  // eslint-disable-next-line class-methods-use-this
  public getDisplayValue(data: unknown): string|null {
    const dataExtractor = new DataExtractor(data);
    const { value, properties } = dataExtractor;

    const element = document.createElement('span');
    element.innerHTML = `${value ?? ''}`;
    if (typeof properties.class === 'string') {
      element.classList.add(properties.class);
    }

    return element.outerHTML;
  }
}
