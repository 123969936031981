import useTenantSettingsStore from '@/stores/tenantSettings';
import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';
import { convertToDateTimeToLocaleString } from '@/helpers/converters/date';

export default class DateTimeCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'dataTime';

  private tenantSettings = useTenantSettingsStore();

  public getDisplayValue(data: unknown): string|null {
    const span = document.createElement('span');
    const { value, properties } = new DataExtractor(data);

    if (!this.columnMetadata) {
      return value as string;
    }

    if (typeof value === 'string' && (this.columnMetadata.show_date || this.columnMetadata.show_time)) {
      const options: Intl.DateTimeFormatOptions = {};
      if (this.columnMetadata.show_date) {
        options.year = 'numeric';
        options.month = this.columnMetadata.human_moments ? 'long' : 'numeric';
        options.day = 'numeric';
      }
      if (this.columnMetadata.show_time) {
        options.hour = 'numeric';
        options.minute = 'numeric';
      }
      span.innerText = convertToDateTimeToLocaleString(value, this.tenantSettings.datetimeLocale, options);
      span.style.whiteSpace = 'nowrap';

      if (typeof properties.color === 'string') {
        span.style.color = properties.color;
      }
    }

    return span.outerHTML;
  }
}
