import DateTimeCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/DateTimeCellDisplayStrategy';
import CellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DefaultCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/DefaultCellDisplayStrategy';
import TimeCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/TimeCellDisplayStrategy';
import DateCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/DateCellDisplayStrategy';
import BooleanCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/BooleanCellDisplayStrategy';
import {
  MetadataColumnSettings,
  RowData,
} from '@/types/ListTypes';
import CurrencyCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/CurrencyCellDisplayStrategy';
import TextCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/TextCellDisplayStrategy';
import UrlCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/UrlCellDisplayStrategy';
import SearchCellDisplayStrategy
  from '@/helpers/mappers/DataTable/cellDisplayStrategies/SearchCellDisplayStrategy';

export default class CellDisplayStrategyFactory {
  private readonly columnMetadata: MetadataColumnSettings;

  private readonly moduleType: string|undefined;

  constructor(columnMetadata: MetadataColumnSettings, moduleType?: string) {
    this.columnMetadata = columnMetadata;
    this.moduleType = moduleType;
  }

  public createCellDisplayStrategy(rowData?: RowData): CellDisplayStrategy {
    switch (this.columnMetadata?.type) {
      case 'time':
        return new TimeCellDisplayStrategy(this.columnMetadata);
      case 'dateTime':
        return new DateTimeCellDisplayStrategy(this.columnMetadata);
      case 'date':
        return new DateCellDisplayStrategy(this.columnMetadata);
      case 'boolean':
        return new BooleanCellDisplayStrategy(this.columnMetadata);
      case 'currency':
        return new CurrencyCellDisplayStrategy(this.columnMetadata);
      case 'text':
        return new TextCellDisplayStrategy(this.columnMetadata);
      case 'url':
        return new UrlCellDisplayStrategy(this.columnMetadata, rowData);
      case 'search':
        return new SearchCellDisplayStrategy(this.columnMetadata);
      case 'number':
      case 'file':
      case 'module':
      case 'tel':
      case 'email':
      case 'position':
        return new DefaultCellDisplayStrategy(this.columnMetadata);
      default:
        return new DefaultCellDisplayStrategy(this.columnMetadata);
    }
  }
}
