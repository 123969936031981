import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import { Time } from '@/helpers/converters/time';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';
import createElement from '@/helpers/mappers/DataTable/cellDisplayDecorator/DecoratorServices';

export default class TimeCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'time';

  // eslint-disable-next-line class-methods-use-this
  public getDisplayValue(data: unknown): string|null {
    let { value, properties } = new DataExtractor(data);

    if (typeof value === 'string') {
      const [hours, minutes] = value.split(':');
      const time = (new Time(hours, minutes)).getTimeString();
      value = time !== '' ? time : value;
    } else {
      value = (value ?? '').toString();
    }

    properties = { ...properties, whiteSpace: 'nowrap', paddingLeft: '10px' };

    return createElement(value ?? '', properties).outerHTML;
  }
}
