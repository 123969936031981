import { IElement } from '@/helpers/mappers/DataTable/cellDisplayDecorator/ConcreteElement';

export default class Decorator implements IElement {
  properties;

  protected component: IElement;

  constructor(component: IElement) {
    this.component = component;
    this.properties = component.properties;
  }

  public getElement(): HTMLElement {
    return this.component.getElement();
  }
}
