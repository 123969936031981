import CellDisplayStrategy from '@/helpers/mappers/DataTable/cellDisplayStrategies/CellDisplayStrategy';
import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';

export default class TextCellDisplayStrategy extends CellDisplayStrategy {
  protected cellType = 'search';

  // eslint-disable-next-line class-methods-use-this
  public getDisplayValue(data: unknown): string | null {
    const { value } = new DataExtractor(data);

    if (typeof value === 'string' && typeof this.columnMetadata?.cell_value === 'string') {
      return value.replace(new RegExp(`(${this.columnMetadata.cell_value})`, 'gi'), '<b><u>$1</u></b>');
    }

    return (value ?? '').toString();
  }
}
