import DataExtractor from '@/helpers/mappers/DataTable/DataExtractor';
import CellValueStrategy from '@/helpers/mappers/DataTable/cellValueStrategies/CellValueStrategy';
import { Value } from '@/types/DataTableTypes';

export default class DefaultCellValueStrategy extends CellValueStrategy {
  // eslint-disable-next-line class-methods-use-this
  public getValue(data: unknown): Value {
    return new DataExtractor(data).value;
  }
}
