export interface IElement {
  properties: Record<string, unknown>;
  getElement(): HTMLElement;
}

export default class ConcreteComponent implements IElement {
  properties;

  private element: HTMLElement = document.createElement('span');

  constructor(value: string, properties: Record<string, unknown>) {
    this.element.innerHTML = value;
    this.properties = properties;
  }

  public getElement(): HTMLElement {
    return this.element;
  }
}
